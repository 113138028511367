@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
   scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  color: #214A51;
  h1 {
    font-size: 53px;
    line-height: 63px;
    font-weight: 700;
    font-style: normal;
    margin: 30px 0;
  }
  h3 {
    font-size: 33px;
    line-height: 43px;
    font-weight: 600;
    font-style: normal;
  }
  h4{
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    font-style: normal;
  }
  p {
    font-size: 22px;
    line-height: 33px;
    font-weight: 400;
    font-style: normal;
  }
  .subtitle_1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 34px;
    font-style: normal;
  }
  .subtitle_2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    font-style: normal;
  }
  .base_2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
  }
  header {
    background-color: #FFFFFF;
    position: fixed;
    z-index: 1;
    width: 100%;
  }
  main {
    background-color: #E5E5E5;
    padding-bottom: 155px;
  }
  footer {
    background-color: #2A5C67;
  }
  .header {
    &__wrapper {
      max-width: 1440px;
      margin: auto;
      padding: 13px 24px;
      display: flex;
      justify-content: space-between;
    }
    &__logo img{
      width: 140px;
    }
    &__contacts {
      display: flex;
      align-items: center;
    }
    &__email {
      margin-left: 28px;
      a {
        text-decoration: none;
        color: inherit;
        transition: 0.2s;
        &:hover {
          color: #00ccc5;
        }
      }
    }
  }
  .main {
    &__wrapper {
      max-width: 1440px;
      margin: auto;
    }
  }
  .feedback1 {
    background-image: url(/img/banner.jpg);
    background-position: 61%;
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    &:after {
      content: "";
      padding-top: 60%;
      display: block;
    }
    &__wrapper {
      background-color: #477f8c;
    }
    &__inner {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      padding: 100px 54px;
      max-width: 40%;
    }
    &__form {
      margin-top: 100px;
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 36px;
        label {
          min-width: 120px;
        }
        &>input {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          font-style: normal;
          padding: 15px 17px;
          border-radius: 4px;
          outline: none;
          width: calc(100% - 170px);
          border: 1px solid #CAD5D9;
          &:hover {
            background-color: #f6fcfc;
          }
        }
        button {
          background-color: #FCE42E;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          font-style: normal;
          padding: 15px 0;
          min-width: 230px;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
  .information {
    margin: 60px 24px;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    &__text,
    &__image {
      width: 45%;
    }
    &__text {
      h3 {
        margin-top: 0px;
      }
    }
    &__image img {
      width: 100%;
    }
  }
  .advantage {
    margin: 90px 24px;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__block {
      width: calc(33% - 57px);
      background-color: #ffffff;
      padding: 64px 24px;
      margin-bottom: 24px;
      img {
        width: 56px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
  .questions {
    margin: 90px 24px;
    display: flex;
    align-items: flex-end;
    p {
      font-size: 16px;
      line-height: 24px;

    }
    a {
      color: #00B2AC;
      fill: #00B2AC;
      text-decoration: none;
      font-size: 16px;
      margin-left: 17px;
    }
    &__wrapper {
      background-color: #fff;
      padding: 50px 32px;
      max-width: 1040px;
    }
    &__button {
      cursor: pointer;
      display: flex;
      align-items: center;
      line-height: 24px;
      transition: .3s;
      margin-bottom: 30px;
      svg{
        margin-bottom: -5px;
        margin-left: -3px;
        transform: rotate(180deg);
        transition: .3s;
      }
      &:hover {
        fill: #00b2ac;
        color: #00b2ac;
      }
    }
    &__info {
      max-height: 0;
      overflow: hidden;
      transition: 1s;
    }
    .show {
      max-height: 1000px;
    }
    .rotate {
      transform: rotate(0deg);
    }
  }
  .customers {
    margin: 24px;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__block {
      font-family: 'Montserrat', sans-serif;
      width: calc(33% - 57px);
      background-color: #ffffff;
      padding: 24px;
      margin-bottom: 24px;
      text-align: center;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      img {
        width: 100%;
      }
      p{
        text-align: left;
        font-size: 18px;
        line-height: 24px;
      }
      a {
        text-align: left;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        text-decoration: none;
        color: #214A51;
        transition: 0.2s;
        &:hover {
          color: #00b2ac;
        }
      }
    }
  }
  .feedback2 {
    background-color: #457e8b;
    background-image: url(/img/bird.png);
    background-position: right bottom;
    background-repeat: no-repeat;
    color: #fff;
    margin: 155px auto;
    margin-bottom: 0;
    max-width: 870px;
    width: 100%;
    background-size: 40%;
    &__inner {
      padding: 27px 54px;
      max-width: 65%;
    }
    &__form {
      margin-top: 40px;
      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 36px;
        label {
          min-width: 120px;
        }
        &>input {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          font-style: normal;
          padding: 15px 17px;
          border-radius: 4px;
          outline: none;
          width: calc(100% - 170px);
          border: 1px solid #CAD5D9;
          &:hover {
            background-color: #f6fcfc;
          }
        }
        button {
          background-color: #FCE42E;
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          font-style: normal;
          padding: 15px 0;
          min-width: 230px;
          border: 0;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    background-color: #2A5C67;
    &__wrapper {
      display: flex;
      max-width: 1440px;
      margin: auto;
      padding: 83px 24px 27px;
    }
    &__logo {
      margin-right: 72px;
    }
    &__phone {
      margin-top: 0;
    }
    &__contacts {
      p {
        color: #FFFFFF;
        display: flex;
        margin: 0 0 10px 0;
        span{
          display: inline-block;
          &:first-child {
            font-size: 13px;
            line-height: 22px;
            color: #CAD5D9;
            min-width: 45px;
            margin-right: 5px;
          }
          &:last-child {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
    &__social {
      margin-top: 38px;
      a {
        margin-right: 20px;
        fill: #FCE42E;
        transition: 0.2s;
        &:hover {
          fill: #00B2AC;
        }
      }
    }
    &__copyright {
      text-align: center;
      color: #CAD5D9;
      font-size: 13px;
      font-weight: 400;
      line-height: 17px;
      padding-bottom: 77px;
    }
  }
}

@media(max-width: 1370px) {
  body {
    h1 {
      font-size: 33px;
      line-height: 43px;
      font-weight: 600;
      margin: 15px 0;
    }
    h3 {
      font-size: 33px;
      line-height: 43px;
      font-weight: 600;

    }
    p {
      font-size: 18px;
      line-height: 27px;
    }
    .subtitle_1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
    }
    .subtitle_2 {
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      font-style: normal;
    }
    .base_2 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      font-style: normal;
    }
    .feedback1 {
      &__inner {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        padding: 107px 31px;
        max-width: 40%;
      }
      &__form {
        margin-top: 60px;
        &-row {
          margin-bottom: 24px;
          label {
            min-width: 100px;
          }
          &>input {
            padding: 10px 17px;
            line-height: 26px;
            width: calc(100% - 130px);
          }
          button {
            font-size: 16px;
            line-height: 22px;
            min-width: 165px;
            padding: 13px 0;
          }
        }
      }
    }
    .feedback2 {
      max-width: 700px;
      &__inner {
        max-width: 60%;
      }
      &__form {
        margin-top: 60px;
        &-row {
          margin-bottom: 24px;
          label {
            min-width: 100px;
          }
          &>input {
            padding: 10px 17px;
            line-height: 26px;
            width: calc(100% - 130px);
          }
          button {
            font-size: 16px;
            line-height: 22px;
            min-width: 165px;
            padding: 13px 0;
          }
        }
      }
    }
    .information {
      padding: 32px;
      &__text,
      &__image {
        width: 48%;
      }
    }
    .advantage {
      h3 {
        font-size: 24px;
      }
    }
    .questions {
      flex-wrap: wrap;
      &__wrapper {
        max-width: 100%;
      }
      a {
        margin-left: auto;
        margin-top: 12px;
      }
    }
  }
}
@media(max-width: 1000px) {
  body {
    .feedback1 {
      background-image: linear-gradient(180deg,#457e8b,rgba(69, 126, 139, 0.72),rgba(255,255,255,0)),url(/img/banner.jpg);
      &:after {
        padding-top: 80%;
      }
      &__inner {
        max-width: 60%;
      }
      &__form {
        margin-top: 60px;
        &-row {
          margin-bottom: 24px;
          label {
            min-width: 100px;
          }
          &>input {
            padding: 10px 17px;
            line-height: 26px;
            width: calc(100% - 130px);
          }
          button {
            font-size: 16px;
            line-height: 22px;
            min-width: 165px;
            padding: 13px 0;
          }
        }
      }
    }
    .advantage {
      &__block {
        width: calc(50% - 57px);
      }
    }
    .information {
      flex-wrap: wrap;
      &__text,
      &__image {
        width: 100%;
      }
    }
    .customers {
      &__block {
        width: calc(50% - 57px);
      }
    }
  }
}

@media(max-width: 700px) {
    body {
      h1 {
        font-size: 33px;
        line-height: 43px;
        font-weight: 600;
        margin: 15px 0;
      }
      h3 {
        font-size: 33px;
        line-height: 43px;
        font-weight: 600;

      }
      p {
        font-size: 18px;
        line-height: 27px;
      }
      .subtitle_1 {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
      }
      .subtitle_2 {
        font-size: 16px;
        line-height: 24px;
      }
      .base_2 {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        font-style: normal;
      }

      .feedback1 {
        background-position: bottom;
        background-size: 130%;
        background-color: #457e8b;
        &:after {
          padding: 0;
        }
        &__inner {
          max-width: 100%;
          position: inherit;
          width: auto;
          padding: 90px 20px;
        }
        &__form {
          margin-top: 40px;
          &-row {
            .subtitle_2 {
              font-size: 20px;
              line-height: 34px;
            }
            flex-wrap: wrap;
            label, input {
              width: 100%;
            }
            .checkbox span {
              padding: 0 0 0 30px;
            }
          }
        }
      }
    }
}
@media(max-width: 600px) {
  body {
    main {
      padding-bottom: 90px;
    }
    h1 {
      font-size: 26px;
      line-height: 33px;
    }
    .header {
      &__wrapper {
        padding: 13px 16px 8px;
      }
      &__email {
        display: none;
      }
      &__logo img{
        width: 81px;
      }
      .subtitle_1 {
        font-weight: 500;
      }
    }
    .information {
      margin: 30px 16px;
      padding: 14px;
      h3 {
        font-size: 26px;
        line-height: 33px;
      }
      p {
        font-size: 16px;
      }
    }
    .advantage {
      margin: 30px 16px;
      &__block {
        width: 100%;
        padding: 26px 14px;
      }
    }
    .questions {
      margin: 30px 16px;
      .subtitle_1 {
        font-size: 20px;
        line-height: 30px;
      }
      &__wrapper {
        padding: 26px 14px;
      }
      .show {
        max-height: 2000px;
      }
    }
    .customers {
      margin: 30px 16px;
      &__block {
        width: 100%;
      }
    }
    .feedback2 {
      margin: 60px auto 0;
      background-size: 60%;
      padding-bottom: 100px;
      &__inner {
        max-width: 100%;
        padding: 20px 32px;
      }
      &__form {
        margin-top: 40px;
        &-row {
          .subtitle_2 {
            font-size: 20px;
            line-height: 34px;
          }
          flex-wrap: wrap;
          label, input {
            width: 100%;
          }
          .checkbox span {
            padding: 0 0 0 30px;
          }
        }
      }
    }
    .footer{
      &__wrapper {
        flex-direction: column-reverse;
        padding: 42px 16px 27px;

      }
      &__contacts p{
        font-size: 20px;
      }
      &__logo {
        margin-right: 0;
        margin-top: 40px;
      }
      &__copyright {
        padding: 20px 16px 40px;
        text-align: left;
      }
    }

  }
}

.checkbox {
  font-size: 13px;
	display: block;
	margin: 0 0 10px 0;
	cursor: pointer;
	user-select: none;
	position: relative;
}
.checkbox input[type=checkbox] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
.checkbox span {
	display: inline-block;
	position: relative;
	padding: 0 0 0 35px;
	line-height: 22px;
}
.checkbox span:before {
  content: "";
  display: inline-block;
  height: 16px;
  left: 0;
  position: absolute;
  top: 5px;
  transition: background .3s ease;
  width: 16px;
  border: 1px solid #cad5d9;
  border-radius: 4px;
  background-position: center;
  background-repeat: no-repeat;
}

/* Checked */
.checkbox input[type=checkbox]:checked + span:before {
	background-image: url(/img/checked.png);
  background-color: #FCE42E;
  border-color: #FCE42E;
}

/* Focus */
.focused span:before {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

/* Hover */
.checkbox span:hover:before {
	filter: brightness(110%);
}

/* Active */
.checkbox span:active:before {
	filter: brightness(80%);
}

.checkbox input[type=checkbox]:disabled + span:before {
	filter: grayscale(100%);
	opacity: 0.6;
}
.checkbox a {
  color: #00b2ac;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    color: #00ccc5;
  }
}
